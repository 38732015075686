import React, { useState } from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import ProductCardPurchase from "./ProductCardPurchase"
import ProductCardHasPurchase from "./ProductCardHasPurchase"
import { StyledProductListItem } from "./productArchive"

const PrimaryProductCardPurchased = props => {

  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false);
  const [user, setUser] = useState(false);
  const { addItem } = useShoppingCart()
  const { 
    id,
    featuredImage, 
  } = props
  const image = {
    imageData: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImage?.node?.alt || ``,
    description: featuredImage?.node?.description || "",
  }
  return (
    identity.user && (identity.user.user_metadata?.roles?.includes('mindfulness-collection') || identity.user.app_metadata?.roles?.includes('mindfulness-collection')) && id == 'cG9zdDo2OTE0' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-reset-anxiety-mindfulness-collection') || identity.user.app_metadata?.roles?.includes('permission-reset-anxiety-mindfulness-collection')) && (id == 'cG9zdDo2OTE0' || id == 'cG9zdDo3MjM0') ||
    identity.user && (identity.user.user_metadata?.roles?.includes('chakra-balancing-collection') || identity.user.app_metadata?.roles?.includes('chakra-balancing-collection')) && id == 'cG9zdDo2OTcx' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('qt-balancing-structure') || identity.user.app_metadata?.roles?.includes('qt-balancing-structure')) && id == 'cG9zdDo2MzQ4' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('qt-brain-healing') || identity.user.app_metadata?.roles?.includes('qt-brain-healing')) && id == 'cG9zdDo2MzU0' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('qt-chakra-balancing') || identity.user.app_metadata?.roles?.includes('qt-chakra-balancing')) && id == 'cG9zdDo2MzY1' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('qt-morning-meditation') || identity.user.app_metadata?.roles?.includes('qt-morning-meditation')) && id == 'cG9zdDo2MzU4' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('shm') || identity.user.app_metadata?.roles?.includes('shm')) && id == 'cG9zdDo3NzQz' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-reset-sleep') || identity.user.app_metadata?.roles?.includes('permission-reset-sleep')) && id == 'cG9zdDo3NzY5' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-reset-anxiety') || identity.user.app_metadata?.roles?.includes('permission-reset-anxiety')) && id == 'cG9zdDo3MjM0' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-reset-sleep-anxiety') || identity.user.app_metadata?.roles?.includes('permission-reset-sleep-anxiety')) && (id == 'cG9zdDo3MjM0' || id == 'cG9zdDo3NzY5') ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-reset') || identity.user.app_metadata?.roles?.includes('permission-reset')) && id == 'cG9zdDo3ODE2' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('permission-bonus') || identity.user.app_metadata?.roles?.includes('permission-bonus')) && id == 'cG9zdDo3OTI5' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('spoon-therapy') || identity.user.app_metadata?.roles?.includes('spoon-therapy')) && id == 'cG9zdDo5NjIx' ||
    identity.user && (identity.user.user_metadata?.roles?.includes('healing-tuning-blueprint') || identity.user.app_metadata?.roles?.includes('healing-tuning-blueprint')) && id == 'cG9zdDo5Nj4x' ||
    id == 'cG9zdDo3Mzcz' ?
    // has purchased 
    <><StyledProductListItem><ProductCardHasPurchase data={props} id={id} user={identity}/></StyledProductListItem></>
     : 
     <>
      
     </>
  );
}

export default PrimaryProductCardPurchased
