import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// import Sticky from 'react-sticky-el'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import ProductList from "../../components/ProductListPurchased"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeaderLedeDiv,
  SectionHeading
} from "../../styledComponents/section"
import { EventListSection } from "../../styledComponents/eventList"
import LoginForm from "../../components/formLogin"
import { Helmet } from "react-helmet"

const Heading = ({ level = 1, location, children, offScreen }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen} className="my-account__header">
    {children}
  </SectionHeading>
)

const MyAccount = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  let allproducts = data.allWpProducts.edges
  let allqtpendantproducts = data.allWpQtPendantProducts.edges
  let allaudiomeditations = data.allAudioMeditations.edges
  let allaudioprograms = data.allAudioPrograms.edges
  const [tabIndex, setTabIndex] = useState(0);
  const navigateTarget = '/my-account'
  const identity = useIdentityContext()
  const [processing, setProcessing] = useState(false)
  const updateRoles = ({ add, remove }) => {
    setProcessing(true)
    identity.authorizedFetch('/api/update-role', {
      method: 'POST',
      body: JSON.stringify({
        action: add ? 'add' : 'remove',
        role: add || remove
      })
    })
      .then(identity.refreshUser)
      .finally(() => setProcessing(false))
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const prod = urlParams.get('product')
    if (prod === 'quantum-touch' || prod === 'quantum-touch-pendants-malas') {
      setTabIndex(1)
    }
    if (prod === 'meditation' || prod === 'meditations') {
      setTabIndex(2)
    }
    if (prod === 'program' || prod === 'programs') {
      setTabIndex(3)
    }
    if (prod && identity.user) {
      updateRoles({ add: prod })
    }
  }, [])


  if (!allproducts.length) {
    return (
      <Layout location={location}>
        <SEO title="All products" />
        <p>
          No event posts found. Add events to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }
  let fName, lName
  if (identity && identity.user) {
    if (identity && identity.user.user_metadata.full_name.trim().indexOf(' ') !== -1) {
      fName = identity.user && identity.user.user_metadata.full_name.split(' ').slice(0, -1).join(' ')
      lName = identity.user && identity.user.user_metadata.full_name.split(' ').slice(-1).join(' ')
    } else {
      fName = identity.user && identity.user.user_metadata.full_name
      lName = ''
    }
  }

  const today = new Date()
  const curHr = today.getHours()
  let greetingText
  if (curHr < 12) {
    greetingText = 'Good morning'
  } else if (curHr < 18) {
    greetingText = 'Good afternoon'
  } else {
    greetingText = 'Good evening'
  }

  return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <Layout location={location} displayNavBorder>
        <Section>
          {identity && identity.user ?
            <>
              <Heading level={1} className="hidden" offScreen={false}>{greetingText}, {fName} </Heading>
              <EventListSection className="event-list">
                <ProductList posts={allproducts} />
              </EventListSection>
            </>
            :
            <>
              <Heading level={1} align="center">
                Log in
              </Heading>
              <SectionHeaderLedeDiv><p>To access your account please log in.</p></SectionHeaderLedeDiv>
              <LoginForm navigateTarget={navigateTarget} />
            </>
          }
        </Section>
      </Layout>
    </>
  )
}

export default MyAccount

export const pageQuery = graphql`
  query WordPressMyAccountArchive {
    allWpProducts: allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
    allWpQtPendantProducts:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "qt-product"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }, 
    allAudioMeditations:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "audio-meditation"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    },
    allAudioPrograms:   allWpProduct(sort: {order: ASC, fields: menuOrder}, filter: {title: {ne: "Permission Bonus"}, productsTag: {nodes: {elemMatch: {slug: {eq: "program"}}}}}) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive 
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
